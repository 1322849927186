<template>
  <div class="update-info">
    <h3 class="headline"><i class="bx bxs-edit bx-tada"></i>Update Information</h3>
    <p class="explain Q-font">Here you can edit public information about yourself.</p>

    <form action="" @submit.prevent="ConfirmUpdateProfile">
      <Input
        label="Name"
        class="input"
        v-model="name"
        :validation="['required', 'minLengthRules']"
        @isValid="nameIsValid"
        :checkValidationFlag="checkValidationFlag"
        type="text"
        icon="bx bx-envelope bx-tada"
      ></Input>

      <Input
        label="User name"
        v-model="username"
        :validation="[]"
        type="text"
        icon="bx bx-envelope bx-tada"
      ></Input>

      <Input
        label="Email"
        v-model="email"
        :validation="['required', 'emailRules']"
        @isValid="EmailIsValid"
        :checkValidationFlag="checkValidationFlag"
        type="email"
        icon="bx bx-envelope bx-tada"
      ></Input>
      <div class="actions">
        <PanelBaseButton type="submit">
          <Loading v-if="loading"></Loading>
          <div v-else>Save Changes</div>
        </PanelBaseButton>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      name: "",
      nameValidation: null,
      username: "",
      email: "",
      emailValidation: null,

      checkValidationFlag: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.firstInitial();
  },
  methods: {
    ...mapActions(["updateProfile", "getUser"]),
    firstInitial() {
      this.name = this.user.name;
      this.username = this.user.username;
      this.email = this.user.email;
    },
    nameInit(inp) {
      this.name = inp;
    },
    usernameInit(inp) {
      this.username = inp;
    },
    emailInit(inp) {
      this.email = inp;
    },
    nameIsValid(v) {
      this.nameValidation = v;
    },
    EmailIsValid(v) {
      this.emailValidation = v;
    },

    ConfirmUpdateProfile() {
      if (this.formIsValid()) {
        const updateData = {
          name: this.name,
          username: this.username,
          email: this.email,
        };
        
        this.loading = true;
        this.updateProfile(updateData)
          .then((res) => {
            this.getUser();
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false;
          });
      }
    },

    formIsValid() {
      if (
        (this.nameValidation || this.nameValidation === null) &&
        (this.emailValidation || this.emailValidation === null)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.update-info {
  @include sm {
    margin-bottom: 30px;
  }
}

.headline {
  color: white;
}
.explain {
  font-size: 14px;
  margin-bottom: 20px;
  color: #a1a1a1;
}
.actions {
  display: flex;
  justify-content: end;
}
</style>
