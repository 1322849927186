<template>
  <div class="charge-wallet">
    <p class="text-explation Q-font">
      <i class="bx bx-info-circle bx-tada"></i> Your earned refferal commission prices can
      transfer to your wallet .
    </p>
    <form
      action=""
      @submit.prevent="submitTransfer"
      v-if="itemSelected.amount || itemSelected.balance"
    >
      <Input
        label="Amount"
        v-model="amount"
        :validation="['required']"
        @isValid="AmountIsValid"
        :checkValidationFlag="checkValidationFlag"
        type="number"
        :max="itemSelected.amount || itemSelected.balance"
        icon="bx bxs-coin-stack bx-tada"
      ></Input>
      <DropDown
        class="dp"
        :selectableItems="selectableWallets"
        :required="true"
        :checkValidationFlag="checkValidationFlag"
        v-model="enteredWallet"
        @isValid="EnteredWalletIsValid"
        place-holder="Select your wallet"
        :isLoading="walletListLoading"
        :disabled="true"
      ></DropDown>

      <PanelBaseButton class="charge-btn lt-hover" type="submit">
        <Loading class="loading" v-if="loading"></Loading>
        <div v-else>
          Transfer
          <i class="bx bx-refresh bx-burst"></i>
        </div>
      </PanelBaseButton>
    </form>
    <h2 v-else style="text-align: center; color: grey">NO COMMISSION !</h2>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["itemSelected"],
  data() {
    return {
      loading: false,
      amount: null,
      amountValidation: null,
      enteredWallet: null,
      enteredWalletValidation: null,
      selectableWallets: [],
      walletListLoading: false,
      checkValidationFlag: false,
    };
  },
  created() {
    this.fetchAllWallets();
  },
  methods: {
    ...mapActions(["transferRequest", "getAllWallets"]),
    AmountIsValid(v) {
      this.amountValidation = v;
    },
    EnteredWalletIsValid(v) {
      this.enteredWalletValidation = v;
    },
    fetchAllWallets() {
      this.walletListLoading = true;
      this.getAllWallets()
        .then((res) => {
          console.log(this.itemSelected);
          this.selectableWallets = res.data.result;
          const findedItem = this.selectableWallets.find((wallet) => {
            //find the wallet that coin name === itemselected name and it was not an commision wallet
            if (wallet.coin === this.itemSelected.coin && !wallet.commission)
              return wallet;
          });
          this.enteredWallet = findedItem.id;
        })
        .finally(() => {
          this.walletListLoading = false;
        });
    },
    submitTransfer() {
      if (this.amountValidation && this.enteredWalletValidation) {
        const transferData = {
          amount: this.amount,
          wallet: this.enteredWallet, //this is destnation wallet id
        };
        const walletId = this.itemSelected.wallet_id || this.itemSelected.id; //selectedWallet id

        console.log({ transferData, walletId });
        this.loading = true;
        this.transferRequest({ walletId, transferData })
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            this.$emit("transferSubmitted");
            this.loading = false;
          });
      } else {
        this.checkValidationFlag = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.charge-wallet {
  padding: 20px;
  background: linear-gradient(200deg, var(--color3), var(--home-bg));
  .text-explation {
    color: white;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .charge-btn {
    width: 100%;
    transition: all 0.5s ease;
    padding: 10px 0;
    margin-top: 10px;
    border-radius: 5px;
    .loading {
      ::v-deep.spinner {
        div {
          background: black;
        }
      }
    }
  }
}
</style>
