<template>
  <div class="settings">
    <h3><i class="bx bxs-cog bx-tada"></i> Setting</h3>
    <div class="content">
      <BaseSwitch
        label="Email verify"
        id="email-verify"
        v-model="isVerified"
        :disabled="!!user.verified || !user.email"
        :warningMessage="
          !user.verified && isVerified ? 'Pending For Verification' : ''
        "
        :successMessage="
          user.verified
            ? `Verified at ${convertDate(user.email_verified_time, 'DMMMYYYY')}`
            : ''
        "
      ></BaseSwitch>
      <BaseSwitch
        label="2Fa Auth"
        id="towFaAuth"
        v-model="google2faEnabled"
      ></BaseSwitch>
      <Loading v-if="loading"></Loading>
      <ReferralInfo></ReferralInfo>
      <section class="activity">
        <header class="header">Activity</header>
        <div class="setting date">
          <p>Created at:</p>
          <p class="date-value Q-font">
            {{ convertDate(user.created_at, "DMMMYYYY") }}
          </p>
        </div>
        <div class="setting date">
          <p>Updated at:</p>
          <p class="date-value Q-font">
            {{ convertDate(user.updated_at, "DMMMYYYY") }}
          </p>
        </div>
      </section>
    </div>
    <!-- 2Fa modal -->
    <BaseModal
      :dialog="twoFaDialog"
      @close="closeTowFaDialog"
      :title="`${
        google2faEnabled ? 'Activate' : 'Deactivate'
      } 2Fa Authentication`"
      :max-width="900"
    >
      <TwoFaAuth
        :isActive="!google2faEnabled"
        @editTwoFaSuccessfull="editTwoFaSuccessfull"
      ></TwoFaAuth>
    </BaseModal>
    <!-- 2Fa modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { remainingTime } from "@/mixins/calendarFormater.js";
import ReferralInfo from "./referral/ReferralInfo.vue";
import TwoFaAuth from "./twoFaAuth/TwoFaAuth.vue";
export default {
  mixins: [remainingTime],
  data() {
    return {
      loading: false,
      isVerified: false,
      google2faEnabled: false,
      isFirstTowFaChange: true,
      twoFaDialog: false,
    };
  },
  components: {
    ReferralInfo,
    TwoFaAuth,
  },
  watch: {
    isVerified(val) {
      if (val && !this.user.verified) {
        this.handleEmailVerify();
      }
    },
    google2faEnabled() {
      if (this.isFirstTowFaChange)
        // Ignore the first change
        this.isFirstTowFaChange = false;
      else this.twoFaDialog = true;
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    // initial user email is verified or not
    this.isVerified = this.user.verified;
    // initial user login 2fa is enabled
    this.google2faEnabled = this.user.google_2fa_enabled;
  },
  methods: {
    ...mapActions(["EmailVerify", "getUser"]),
    handleEmailVerify() {
      this.EmailVerify().then(() => {});
    },
    closeTowFaDialog() {
      this.twoFaDialog = false;
      this.isFirstTowFaChange = true;
      this.google2faEnabled = !this.google2faEnabled;
    },
    async editTwoFaSuccessfull() {
      this.twoFaDialog = false;
      await this.getUser();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.settings {
  border: 2px solid white;
  border-radius: 10px;
  height: 100%;
  padding: 10px;
  margin-left: 5px;
  @include md {
    background: rgba(27, 156, 255, 0.419) !important;
  }
  @include sm {
    padding: 5px;
    border: none;
  }
  p {
    font-size: 13px;
  }
  .content {
    width: 100%;
    .setting {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
      button {
        padding: 1px 10px;
        border-radius: 5px;
      }
    }
    .activity {
      box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3),
        inset 10px 10px 10px rgba(0, 0, 0, 0.3);
      padding: 5px;
      border-radius: 5px;
      .header {
        display: flex;
        justify-content: center;
      }
      .date {
        .date-value {
          font-size: 11px;
          color: grey;
        }
      }
    }
  }
}
</style>
