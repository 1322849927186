<template>
  <div class="ref-container">
    <header class="header">Referral</header>
    <p class="Q-font title">
      Refer a friend and earn {{ referralPercent }}% commission of their charges.
    </p>
    <section class="item flex-space-between">
      <span class="name">Referred By</span>
      <span>{{ user.referral_by ? user.referral_by : "-" }}</span>
    </section>
    <section class="item flex-space-between">
      <span class="name">My Referrals</span>
      <span>{{ user.referral_count ? user.referral_count : "0" }} </span>
    </section>
    <section class="item">
      <ul class="prices">
        <span class="name">Total earned commissions</span>
        <li class="price-item" v-for="(price, i) in user.referral_price" :key="i">
          <span>{{ price.coin }}</span>
          <span
            >{{ price.amount }}
            <!-- <button class="withdraw-btn" @click="withdrawRequest(price)">
              withdraw
            </button> -->
            <Tooltip text="Withdraw Money" style="margin: 0 3px">
              <button style="background: none" @click.stop="withdrawRequest(price)">
                <i class="bx bx-money-withdraw" style="color: orange"></i></button
            ></Tooltip>
            <Tooltip text="Transfer to wallet">
              <button style="background: none" @click.stop="TransferCommission(price)">
                <i class="bx bx-refresh" style="color: yellow"></i></button
            ></Tooltip>
          </span>
        </li>
      </ul>
    </section>
    <Tooltip :text="!isCodeCopied ? 'Copy to clipboard' : 'Copied!'">
      <section class="item cursor-pointer flex-space-between" @click="copyCode">
        <span class="name">Your code</span>
        <span
          >{{ user.referral_code }}
          <i class="bx bxs-copy" :style="isCodeCopied ? 'color:#2aff00' : ''"></i
        ></span>
      </section>
    </Tooltip>
    <Tooltip :text="!isLinkCopied ? 'Copy to clipboard' : 'Copied!'">
      <section class="item" @click="copyLink">
        <div class="flex-space-between cursor-pointer">
          <span class="name">Your link</span>
          <i class="bx bxs-copy" :style="isLinkCopied ? 'color:#2aff00' : ''"></i>
        </div>
        <p class="link Q-font">{{ referralLink }}</p>
      </section>
    </Tooltip>

    <!-- withdraw money modal -->
    <BaseModal
      :dialog="withdrawMoneyDialog"
      @close="withdrawMoneyDialog = false"
      title="Withdraw Commission"
    >
      <WithdrawMoney
        :itemSelected="selectedPrice"
        @withdrawSubmitted="onWithdrawSubmit"
      ></WithdrawMoney>
    </BaseModal>
    <!-- withdraw money modal -->

    <!-- Transfer commission modal -->
    <BaseModal
      :dialog="transferCommissionDialog"
      @close="transferCommissionDialog = false"
      title="Commission price transfer"
    >
      <TransferToWallet
        :itemSelected="selectedPrice"
        @transferSubmitted="onTransferSubmit"
      ></TransferToWallet>
    </BaseModal>
    <!-- Transfer commission modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import WithdrawMoney from "./WithdrawMoney.vue";
import TransferToWallet from "./TransferToWallet.vue";
export default {
  components: {
    WithdrawMoney,
    TransferToWallet,
  },
  data() {
    return {
      isCodeCopied: false,
      isLinkCopied: false,
      selectedPrice: {},
      withdrawMoneyDialog: false,
      transferCommissionDialog: false,
      referralPercent: 0,
    };
  },
  computed: {
    ...mapGetters(["user"]),

    referralLink() {
      return `${process.env.VUE_APP_SITE_URL}/register?referral=${this.user.referral_code}`;
    },
  },
  created() {
    this.fetchConfigs();
  },
  methods: {
    ...mapActions(["getUser", "getConfigs"]),
    fetchConfigs() {
      this.getConfigs().then((res) => {
        this.referralPercent = res.referral_percent;
      });
    },
    withdrawRequest(item) {
      this.selectedPrice = item;
      this.withdrawMoneyDialog = true;
    },

    onWithdrawSubmit() {
      this.withdrawMoneyDialog = false;
      this.getUser();
    },

    TransferCommission(item) {
      this.selectedPrice = item;
      this.transferCommissionDialog = true;
    },
    onTransferSubmit() {
      this.transferCommissionDialog = false;
      this.getUser();
    },
    copyCode() {
      navigator.clipboard.writeText(this.user.referral_code);
      this.isCodeCopied = true;
      setTimeout(() => {
        this.isCodeCopied = false;
      }, 3000);
    },
    copyLink() {
      navigator.clipboard.writeText(this.referralLink);
      this.isLinkCopied = true;
      setTimeout(() => {
        this.isLinkCopied = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.ref-container {
  width: 100%;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px 5px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3), inset 10px 10px 10px rgba(0, 0, 0, 0.3);
  .header {
    display: flex;
    justify-content: center;
    color: yellow;
  }
  .title {
    font-size: 10px;
    font-weight: 200;
    text-align: center;
    margin: 10px 0;
  }
  .item {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3), inset 3px 3px 3px rgba(0, 0, 0, 0.3);
    margin-bottom: 15px;
    padding: 5px;
    border-radius: 5px;

    span {
      font-size: 12px;
      &.name {
        color: yellow;
      }
    }
    .prices {
      margin: 0 auto;
      .price-item {
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid yellow;
        .withdraw-btn {
          padding: 2px 3px;
          border-radius: 2px;
          transition: all 0.3s ease;
          margin-left: 2px;
          color: #458d45;
          background: #00000092;
          border: 1px solid #458d45;
          &:hover,
          &:active {
            color: #00000092;
            background: #458d45;
          }
        }
      }
    }
    .link {
      font-size: 11px;
      text-align: center;
      overflow: auto;
    }
    i {
      color: yellow;
    }
  }
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
