<template>
  <div class="no-avatar-container">
    <input
      class="file-input"
      type="file"
      name="avatar"
      id="avatar"
      ref="avatarInput"
      @change="onChooseImage"
    />
    <i
      class="no-image-icon bx bxs-cloud-upload"
      @click="$refs.avatarInput.click()"
    >
      <span style="font-size: 11px; color:white" v-if="uploadEvent">
       Uploading  {{ Math.round((uploadEvent.loaded / uploadEvent.total) * 100) }} %</span
      >
      <span style="font-size: 11px" v-else>take a photo</span>
    </i>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["uploadEvent"]),
  },
  methods: {
    ...mapActions(["uploadImage", "setUserManually"]),
    onChooseImage(e) {
      const formData = new FormData();
      formData.append("avatar", e.target.files[0]);
      const link = "users/profile/avatar";
      this.uploadImage({ formData, link })
        .then((result) => {
          
          this.setUserManually(result.data.result);
        })
        .catch((err) => {
          
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-avatar-container {
  border: 2px dashed white;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 300px;
  max-height: 300px;
  height: 50vh;
  background: rgba(0, 255, 255, 0.273);
  .file-input {
    display: none;
  }
  .no-image-icon {
    transition: all 0.3s ease;
    color: white;
    font-size: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
      font-size: 130px;
      cursor: pointer;
      color: orange;
    }
  }
}
</style>
