<template>
  <div class="profile container">
    <div class="profile-wrapper">
      <section class="info">
        <div class="avatar">
          <div class="avatar-image-box" :style="0 ? 'border: 2px dashed white;' : ''">
            <NoAvatar v-if="!user.avatar"></NoAvatar>
            <AvatarImage v-else></AvatarImage>
            <span class="active-status">{{ user.status }}</span>
          </div>
          <div class="explain-container">
            <h3 class="headline">
              <i class="bx bx-info-circle bx-tada"></i> User Information
            </h3>
            <p class="explain Q-font">
              Other user can see your profile and find you using your profile information.
            </p>
            <ul>
              <li>
                <span class="first-child"><i class="bx bxs-id-card"></i>ID </span
                ><span class="last-child">{{ user.id ? user.id : "---" }}</span>
              </li>
              <li>
                <span class="first-child"><i class="bx bxs-user"></i>Name </span
                ><span class="last-child">{{ user.name ? user.name : "---" }}</span>
              </li>
              <li>
                <span class="first-child"><i class="bx bxs-user-badge"></i>Username </span
                ><span class="last-child">{{
                  user.username ? user.username : "---"
                }}</span>
              </li>
              <li>
                <span class="first-child"><i class="bx bxs-envelope"></i>Email </span
                ><span class="last-child">{{ user.email ? user.email : "---" }}</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section class="main-form">
        <div class="left">
          <UpdateInformations></UpdateInformations>
          <PasswordUpdate></PasswordUpdate>
        </div>
        <div class="right">
          <Settings></Settings>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UpdateInformations from "@/components/panelSections/profile/UpdateInformations.vue";
import PasswordUpdate from "@/components/panelSections/profile/PasswordUpdate.vue";
import Settings from "@/components/panelSections/profile/Settings.vue";
import NoAvatar from "@/components/panelSections/profile/avatar/NoAvatar.vue";
import AvatarImage from "@/components/panelSections/profile/avatar/AvatarImage.vue";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["user"]),
  },
  components: {
    PasswordUpdate,
    UpdateInformations,
    Settings,
    AvatarImage,
    NoAvatar,
  },
  created() {
    this.fetchUser();
  },
  methods: {
    ...mapActions(["getUser"]),
    fetchUser() {
      this.getUser();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.profile {
  .profile-wrapper {
    width: 100%;
    display: flex;
    @include md {
      display: unset;
    }
    .info {
      background: var(--home-bg);
      width: 30%;
      border-radius: 10px;
      @include md {
        width: 100%;
        border-radius: unset;
        background: none;
      }
      .avatar {
        padding: 5px 2px;
        .avatar-image-box {
          position: relative;
          margin-bottom: 50px;
          .active-status {
            position: absolute;
            right: 8%;
            bottom: 6%;
            background: #458d45;
            padding: 3px 10px 3px 20px;
            border-radius: 20px;
            user-select: none;
            font-size: 12px;
            color: white;
            border: 2px solid white;
            &::before {
              content: "";
              position: absolute;
              left: 6px;
              top: 50%;
              transform: translateY(-50%);
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: green;
            }
          }
        }
      }
      .explain-container {
        padding: 20px 40px;
        @include lg {
          padding: 10px;
        }
        @include md {
          padding: 20px 30px;
        }
        @include sm {
          padding: 5px;
        }
        .headline {
          color: white;
        }
        .explain {
          color: var(--color8);
          font-size: 14px;
        }
        ul {
          margin: 20px 0;
          padding: 0 10px;
          color: white;
          li {
            margin: 3px 0;
            .first-child {
              color: var(--color7);
              display: flex;
              align-items: center;
              white-space: nowrap;
              i {
                font-size: 18px;
                margin-right: 8px;
                color: var(--color7);
              }
            }
            .last-child {
              font-size: 13px;
              @include lg {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .main-form {
      background: var(--home-bg);
      width: 70%;
      // border: 2px solid white;
      border-radius: 10px;
      margin: 0 20px;
      padding: 20px 40px;
      display: flex;
      @include md {
        width: 100%;
        margin: 0;
        border-radius: unset;
        background: none;
      }
      @include sm {
        padding: 5px;
      }
      .left {
        width: 65%;
      }
      .right {
        width: 35%;
        color: white;
      }
      @include lg {
        display: block;
        .left {
          width: 100%;
        }
        .right {
          width: 100%;
        }
      }
    }
  }
}
button {
  padding: 7px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
</style>
