<template>
  <div class="charge-wallet">
    <p class="text-explation Q-font">
      <i class="bx bx-info-circle bx-tada"></i> Your withdrawal requests should be
      proccessed within 2 business days.Check your withdrawals status progress at your
      withdrawal requests list.
    </p>
    <form
      action=""
      @submit.prevent="submitWithdraw"
      v-if="itemSelected.amount || itemSelected.balance"
    >
      <Input
        label="Amount"
        v-model="amount"
        :validation="['required']"
        @isValid="AmountIsValid"
        :checkValidationFlag="checkValidationFlag"
        type="number"
        :max="itemSelected.amount || itemSelected.balance"
        icon="bx bxs-coin-stack bx-tada"
      ></Input>
      <Input
        label="Wallet Address"
        v-model="walletAddress"
        :validation="['required']"
        @isValid="WalletAddressIsValid"
        :checkValidationFlag="checkValidationFlag"
        type="text"
        icon="bx bxs-wallet bx-tada"
      ></Input>

      <Textarea
        class="input"
        label="Description"
        v-model="description"
        type="text"
        icon="bx bx-message-detail bx-tada"
      ></Textarea>

      <PanelBaseButton class="charge-btn lt-hover" type="submit">
        <Loading class="loading" v-if="loading"></Loading>
        <div v-else>
          Request withdraw
          <i class="bx bx-money-withdraw bx-burst"></i>
        </div>
      </PanelBaseButton>
    </form>
    <h2 v-else style="text-align: center; color: grey">NO COMMISSION !</h2>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["itemSelected"],
  data() {
    return {
      loading: false,
      amount: null,
      amountValidation: null,
      walletAddress: null,
      walletAddressValidation: null,
      description: "",
      checkValidationFlag: false,
    };
  },
  methods: {
    ...mapActions(["withdrawRequest"]),
    AmountIsValid(v) {
      this.amountValidation = v;
    },
    WalletAddressIsValid(v) {
      this.walletAddressValidation = v;
    },
    submitWithdraw() {
      if (this.amountValidation && this.walletAddressValidation) {
        const walletId = this.itemSelected.wallet_id || this.itemSelected.id;
        const withdrawData = {
          amount: this.amount,
          address: this.walletAddress,
          description: this.description,
        };
        this.loading = true;
        this.withdrawRequest({ walletId, withdrawData })
          .then((res) => {})
          .catch(() => {})
          .finally(() => {
            this.$emit("withdrawSubmitted");
            this.loading = false;
          });
      } else {
        this.checkValidationFlag = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.charge-wallet {
  padding: 20px;
  background: linear-gradient(200deg, var(--color3), var(--home-bg));
  .text-explation {
    color: white;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .charge-btn {
    width: 100%;
    transition: all 0.5s ease;
    padding: 10px 0;
    margin-top: 10px;
    border-radius: 5px;
    .loading {
      ::v-deep.spinner {
        div {
          background: black;
        }
      }
    }
  }
}
</style>
