<template>
  <div class="tow-fa-auth">
    <!-- 2fa auth is disable and user want to enable -->
    <figure class="not-active-informations" v-if="!isActive">
      <h5>Two-Factor Authenticator</h5>
      <p class="explation Q-font">
        An authenticator app lets you generate security codes on yout phone without
        needing to receive text messages.
      </p>
      <p class="explation Q-font">
        To configure your authenticator app with communifire :
      </p>
    </figure>
    <section class="not-active" v-if="!isActive">
      <!-- step 1 -->
      <figure class="step-1" style="justify-content: center" v-if="loading">
        <Loading></Loading>
      </figure>

      <figure class="step-1" v-else>
        <p style="text-align: center">Step 1</p>
        <p class="explation Q-font">Use your app to scan QR code below.</p>
        <img :src="qrcode" width="50%" alt="" class="qr-code" />
        <p class="explation Q-font">Or enter the key manually</p>
        <p>{{ secret }}</p>
      </figure>
      <!-- step 2 -->
      <figure class="step-2">
        <p style="text-align: center">Step 2</p>
        <p class="explation Q-font" style="text-align: center">
          Enter the security code generated by your mobile authenticator app. Click the
          verify button to make sure it's configured correctly.
        </p>
        <form action="" @submit.prevent="activeSubmit" style="margin-top: auto">
          <Input
            label="Verification code"
            v-model="enteredCode"
            :validation="['required']"
            @isValid="enteredCodeIsValid"
            :checkValidationFlag="checkValidationFlag"
            type="number"
            icon="bx bx-lock bx-tada"
          ></Input>
          <PanelBaseButton type="submit">
            <Loading v-if="verifyLoading"></Loading>
            <div v-else>Verify</div>
          </PanelBaseButton>
        </form>
      </figure>
    </section>

    <!-- 2fa auth is enable and user want to desable -->
    <section v-else>
      <form action="" @submit.prevent="activeSubmit" style="margin-top: auto">
        <h5>Disable Two-Factor Authenticator</h5>
        <p class="explation Q-font" style="margin: 15px 0">
          Enter the verification code generated by your mobile application.
        </p>
        <Input
          label="6-digit authentication code"
          v-model="enteredCode"
          :validation="['required']"
          @isValid="enteredCodeIsValid"
          :checkValidationFlag="checkValidationFlag"
          type="number"
          icon="bx bx-lock bx-tada"
        ></Input>
        <PanelBaseButton type="submit">
          <Loading v-if="verifyLoading"></Loading>
          <div v-else>Disable</div>
        </PanelBaseButton>
      </form>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["isActive"],
  data() {
    return {
      loading: false,
      verifyLoading: false,
      qrcode: "",
      secret: "",
      enteredCode: "",
      enteredCodeValidation: false,
      checkValidationFlag: false,
    };
  },
  computed: {
    ...mapGetters(["rules", "user"]),
  },
  mounted() {
    if (!this.user.google_2fa_enabled) this.generateTwoFaAuth();
  },
  methods: {
    ...mapActions(["generateTwoFa", "changeTwoFaStatus"]),
    enteredCodeIsValid(v) {
      this.enteredCodeValidation = v;
    },
    generateTwoFaAuth() {
      this.loading = true;
      this.generateTwoFa()
        .then((result) => {
          this.qrcode = result.data.result.auth2fa_qrcode;
          this.secret = result.data.result.google2fa_secret;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    activeSubmit() {
      if (this.enteredCodeValidation) {
        this.verifyLoading = true;

        // set variable
        let payload = {
          code: this.enteredCode,
          status: this.user.google_2fa_enabled ? "inactive" : "active",
        };
        this.changeTwoFaStatus(payload)
          .then(() => {
            this.$emit("editTwoFaSuccessfull");
          })
          .catch((err) => {})
          .finally(() => {
            this.verifyLoading = false;
          });
      } else {
        this.checkValidationFlag = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.tow-fa-auth {
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
  background: var(--color3);
  .explation {
    font-size: 12px;
    font-weight: 100;
    margin-bottom: 3px;
    color: rgb(220, 220, 220);
  }
  .not-active-informations {
  }
  .not-active {
    display: flex;
    @include sm {
      display: block;
    }
    .step-1 {
      width: 50%;
      border: 1px solid grey;
      padding: 3px;
      margin: 10px 2px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include sm {
        width: 100%;
      }
      .qr-code {
        aspect-ratio: 1;
      }
    }
    .step-2 {
      width: 50%;
      border: 1px solid grey;
      padding: 3px;
      margin: 10px 2px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include sm {
        width: 100%;
      }
    }
  }
}
</style>
