<template>
  <div class="password-update">
    <h3 class="headline"><i class="bx bx-lock bx-tada"></i> Update Password</h3>
    <p class="explain Q-font">Here you can edit public information about yourself.</p>
    <h6 class="no-old-pass-warning" v-if="!user.email">
      <i class="bx bx-error"></i> No old password? Proceed anyway.
    </h6>
    <form action="" @submit.prevent="resetPassword">
      <Input
        label="Old Password"
        v-model="oldPass"
        :validation="[]"
        :checkValidationFlag="checkValidationFlag"
        type="password"
        icon="bx bx-lock bx-tada"
      ></Input>
      <Input
        label="New Password"
        v-model="newPass"
        :validation="[]"
        :checkValidationFlag="checkValidationFlag"
        type="password"
        icon="bx bx-lock bx-tada"
      ></Input>
      <Input
        label="New Password Confirm"
        v-model="newPassConfirm"
        :validation="[]"
        :checkValidationFlag="checkValidationFlag"
        type="password"
        icon="bx bx-lock bx-tada"
      ></Input>
      <p :style="passwordsMatch ? 'opacity:0' : 'opacity:1'" class="passwords-not-match">
        <i class="bx bxs-error-circle bx-tada"></i> Passwords do Not match. Try again
      </p>
      <div class="actions">
        <PanelBaseButton type="submit">
          <Loading v-if="loading"></Loading>
          <div v-else>Password update</div>
        </PanelBaseButton>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      oldPass: null,
      newPass: null,
      newPassConfirm: null,

      checkValidationFlag: false,

      passwordsMatch: true,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    newPassConfirm() {
      this.passwordsIsMatch();
    },
    newPass() {
      if (this.newPassConfirm) this.passwordsIsMatch();
    },
  },
  methods: {
    ...mapActions(["updatePassword"]),
    passwordsIsMatch() {
      if (this.newPass === this.newPassConfirm) {
        //check passwords match
        this.passwordsMatch = true;
      } else {
        //if passwords not match
        this.passwordsMatch = false;
      }
    },
    resetPassword() {
      const changePasswordData = {
        old: this.oldPass,
        password: this.newPass,
        password_confirmation: this.newPassConfirm,
      };
      if (this.passwordsMatch) {
        this.loading = true;
        this.updatePassword(changePasswordData)
          .then((res) => {
            if (res.data.success) {
              
              this.oldPass = null;
              this.newPass = null;
              this.newPassConfirm = null;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.password-update {
  @include sm {
    margin-bottom: 30px;
  }
  .passwords-not-match {
    color: red !important;
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 12px;
    i {
      font-size: 16px;
      margin-right: 4px;
    }
  }
}
.headline {
  color: white;
}
.explain {
  font-size: 14px;
  margin-bottom: 20px;
  color: var(--color8);
  // font-style: italic;
}
.no-old-pass-warning {
  color: rgb(255, 251, 0);
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 100;
  display: flex;
  align-items: end;
  i {
    font-size: 16px;
    margin-right: 3px;
  }
}
.actions {
  display: flex;
  justify-content: end;
}
</style>
