<template>
  <div class="img-container">
    <img :src="storageImageLink" alt="" class="avatar-img" />

    <span class="uploading-text" v-if="uploadEvent">
      Uploading
      {{ Math.round((uploadEvent.loaded / uploadEvent.total) * 100) }} %
    </span>
    <div class="actions" v-else>
      <input
        class="file-input"
        type="file"
        name="avatar"
        id="avatar"
        ref="avatarInput"
        @change="onChooseImage"
      />
      <i class="delete bx bxs-trash" @click="deleteImage"></i>
      <i class="edit bx bxs-edit" @click="$refs.avatarInput.click()"></i>
    </div>
    <h1 style="max-width: 100px"></h1>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["user", "uploadEvent"]),
    storageImageLink() {
      if (this.user.avatar.includes("http")) return this.user.avatar;
      else return `${process.env.VUE_APP_ROOT_STORAGE}/${this.user.avatar}`;
    },
  },
  methods: {
    ...mapActions(["uploadImage", "setUserManually"]),
    deleteImage() {
      const link = "users/profile/avatar";
      const formData = null;
      this.uploadImage({ link, formData })
        .then((result) => {
          // this.$emit("deleteSuccessfully", result);
          this.setUserManually(result.data.result);
        })
        .catch((err) => {});
    },

    onChooseImage(e) {
      const formData = new FormData();
      formData.append("avatar", e.target.files[0]);
      const link = "users/profile/avatar";
      this.uploadImage({ formData, link })
        .then((result) => {
          this.setUserManually(result.data.result);
        })
        .catch((err) => {});
    },

    checkAvatar(link) {
     
     
      if (link.includes("http")) {
        return link;
      } else {
        return `${process.env.VUE_APP_ROOT_STORAGE}/${link}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-container {
  position: relative;
  display: flex;
  justify-content: center;
  &:hover {
    .actions {
      display: flex;
    }
  }
  .uploading-text {
    position: absolute;
    left: 50%;
    top: 0;
    width: 80%;
    height: 100%;
    transform: translateX(-50%);
    border-radius: 50%;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }
  .avatar-img {
    width: 80%;
    border-radius: 50%;
    object-fit: cover;
    border: 5px solid white;
    aspect-ratio: 1/1;
    background: rgba(0, 0, 0, 0.5);
  }
  .actions {
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 98%;
    display: none;
    background: rgba(0, 255, 255, 0.273);
    border-radius: 50%;
    aspect-ratio: 1/1;
    justify-content: center;
    align-items: center;
    .file-input {
      display: none;
    }
    i {
      font-size: 40px;
      text-shadow: 0 0 5px black;
      margin-right: 3px;
      cursor: pointer;
      color: #00ffff;
      &.delete {
        &:hover {
          color: red;
          transform: scale(1.2);
        }
      }
      &.edit {
        &:hover {
          color: #30c930;
          transform: scale(1.2);
        }
      }
    }
  }
}
</style>
